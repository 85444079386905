const PROJECTS = [
  {
    name: "El-Andariego",
    code_link: "https://github.com/devloos/El-Andariego",
    test_link: "https://www.elandariegotruck.com/",
    video_path: "assets/demos/El-Andariego.mp4",
    description:
      "A custom website built for El Andariego, a food truck, with online ordering and a dynamic menu system.",
    creation:
      "Built with Vue.js, PHP, MongoDB, and Tailwind CSS, utilizing the Square API for online transactions.",
    date: "August 2022",
    category: "web",
  },
  {
    name: "Pokemon-Clone",
    code_link: "https://github.com/devloos/Pokemon-Clone",
    test_link: "",
    video_path: "assets/demos/Pokemon-Clone.mp4",
    description:
      "A game inspired by Pokémon, featuring turn-based mechanics and RPG elements.",
    creation:
      "Developed in C++ using raylib to create a turn-based RPG experience",
    date: "July 2022",
    category: "backend",
  },
  {
    name: "Posting-Site",
    code_link: "",
    test_link: "",
    video_path: "assets/demos/Posting-Site.mp4",
    description:
      "A platform for users to post and share content, with features for media uploads and interactions.",
    creation:
      "Built using modern web technologies such as Vue.js, Node.js, and MongoDB for a full-stack experience.",
    date: "April 2022",
    category: "web",
  },
  {
    name: "Sales-System",
    code_link: "https://github.com/devloos/Sales-System",
    test_link: "",
    video_path: "assets/demos/Sales-System.mp4",
    description:
      "A point-of-sale system designed to streamline sales, manage inventory, and process transactions.",
    creation:
      "Created with modern C++ featuring manual memory management, RAII, and a console-based UI.",
    date: "August 2022",
    category: "backend",
  },
  {
    name: "Snake-Game",
    code_link: "https://github.com/devloos/Snake-Game",
    test_link: "",
    video_path: "assets/demos/Snake-Game.mp4",
    description:
      "A classic Snake game with a modern twist, developed with efficient memory management and modular design.",
    creation:
      "Developed in C++ using the ncurses library for a text-based user interface.",
    date: "March 2022",
    category: "backend",
  },
];

class Project {
  static GetByCategory = (category) => {
    return PROJECTS.filter((el) => el.category === category);
  };

  static GetByName = (name) => {
    return PROJECTS.find((el) => el.name === name);
  };
}

export default Project;
