<template>
  <div class="container text-light">
    <header class="row d-grid justify-content-center pt-3 mb-4">
      <div class="text-center">
        <h1 class="fs-1 text-uppercase mb-0">Carlos Aguilera</h1>
        <h5 class="fs-5 text-muted">Software Engineer</h5>
      </div>
    </header>
    <div class="row ps-lg-5 pe-lg-5 justify-content-center text-center">
      <img
        src="HunkPic.png"
        class="col-2 img-thumbnail border border-0 p-0 rounded-pill mb-5"
        alt="Hunk Los"
      />
      <p class="text-break">
        Web Developer for Ethika Inc. Some of my goals are to get into a good university
        known for Computer Science. Accomplishing this would lead me to another goal of
        mine : pushing for a FAANG SWE Position.
      </p>
      <p class="text-break">
        Some of the schools that I will be applying to in the Fall semester of 2023 are [
        UCI, UCSC, UCR, UCSD, SLO, SDSU, CSULA, SFSU, Pomona ], just to name a few. My top
        choices are UCI, SFSU, and UC Santa Cruz.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header-V',
};
</script>
