const TECHNOLOGIES = [
  {
    image_path: "assets/tech/Bootstrap.svg",
    name: "Bootstrap",
  },
  {
    image_path: "assets/tech/C++.png",
    name: "C++",
  },
  {
    image_path: "assets/tech/CMake.png",
    name: "CMake",
  },
  {
    image_path: "assets/tech/CSS.png",
    name: "CSS",
  },
  {
    image_path: "assets/tech/ExpressJs.png",
    name: "ExpressJs",
  },
  {
    image_path: "assets/tech/Git.png",
    name: "Git",
  },
  {
    image_path: "assets/tech/HTML.png",
    name: "HTML",
  },
  {
    image_path: "assets/tech/JavaScript.svg",
    name: "JavaScript",
  },
  {
    image_path: "assets/tech/Linux.png",
    name: "Linux",
  },
  {
    image_path: "assets/tech/Mongodb.png",
    name: "Mongodb",
  },
  {
    image_path: "assets/tech/NodeJs.png",
    name: "NodeJs",
  },
  {
    image_path: "assets/tech/Office.png",
    name: "Office",
  },
  {
    image_path: "assets/tech/PHP.png",
    name: "PHP",
  },
  {
    image_path: "assets/tech/Raylib.png",
    name: "Raylib",
  },
  {
    image_path: "assets/tech/VueJs.png",
    name: "VueJs",
  },
];

const GetTechnologies = () => {
  return TECHNOLOGIES;
};

export default GetTechnologies;
